import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline"

const VideoTemplate = ({ data }) => (
  <Layout>
    <Seo title="Shows" />
    <button className="flex gap-2 text-lg" onClick={() => navigate(-1)}>
      <ArrowNarrowLeftIcon className="w-6"/> Go Back
    </button>
    <div className="embed-container mt-10 full-width">
        <iframe src={"https://player.vimeo.com/video/" + data.vimeoVideo.id} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title={data.vimeoVideo.title}></iframe>
    </div>
    <h1 className="font-black text-4xl mt-10">{data.vimeoVideo.title}</h1>
    <div className="flex gap-4 mb-6">
        <p className="text-gray-500">{data.vimeoVideo.duration}</p>
        <p>{data.vimeoVideo.date}</p>
    </div>
    <p className="mb-10">{data.vimeoVideo.description}</p>
  </Layout>
)

export const query = graphql`
query($slug: String!) {
    vimeoVideo(id: {eq: $slug}){
      id
      thumbnail
      title
      duration
      description
      date
      showcase {
        name
      }
    }
  }
`

export default VideoTemplate
